<template>
  <v-chip
    pill
  >
    <v-avatar left>
      <v-img
        :src="getCarrierIcon(carrier)"
        :alt="$t('enum.carriers.' + carrier)"
        :title="$t('enum.carriers.' + carrier)"
      />
    </v-avatar>
    {{ $t('enum.carriers.' + carrier) }}
  </v-chip>
</template>
<script>
import shipment from '@/mixins/shipment'

export default {
  name: 'CarrierChip',
  mixins: [ shipment ],
  props: {
    carrier: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>

</style>
