<template>
  <v-select
    v-model="stateValue"
    :items="carriers"
    :dense="dense"
    hide-details
    prepend-inner-icon="mdi-transit-connection-variant"
    label="Transporteur"
    clearable
    outlined
    multiple
    small-chips
    deletable-chips
    item-text="label"
    item-value="value"
  >
    <template v-slot:item="{item, attrs, on}">
      <v-list-item
        #default="{ active }"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-icon>
          <CarrierIcon :carrier="item.value" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import CarrierIcon from '@/components/base/Shipment/CarriererIcon.vue'

export default {
  components: { CarrierIcon },
  props: {
    state: {
      type: Array,
      default: () => ([]),
    },
    dense: Boolean,
  },
  data() {
    return {
      carriers: [
        { label: 'Colissimo', value: 'COLISSIMO' },
        { label: 'Mondial Relay', value: 'MONDIAL_RELAY' },
        { label: 'BPost', value: 'BPOST' },
        { label: 'Chronopost', value: 'CHRONOPOST' },
        { label: 'DHL', value: 'DHL' },
      ],
    }
  },
  computed:{
    stateValue: {
      get () { return this.state  },
      set (value) { this.$emit('update:state', value) },
    },
  },
}
</script>

<style scoped>

</style>
